import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';

import axios from 'axios'; // You might need to install axios

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Signup = ({ onSignupComplete }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const returnUrl = query.get("returnUrl") || '/login'; // Fallback to a default route after successful signup
  const [showPassword, setShowPassword] = useState(false);

  const [formError, setFormError] = useState(null);

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });

  const [passwordError, setPasswordError] = useState(null); // New state for password errors
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const checkUserExists = async (email) => {
    try {
      const response = await axios.post('https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/checkUser', { email });
      return response.data.exists;
    } catch (error) {
      console.error('Error checking user existence:', error);
      return false;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      password,
    }));

    const requirements = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[^A-Za-z0-9]/.test(password),
    };
    setPasswordRequirements(requirements);
  };

  const handleNextClick = async () => {
    const userExists = await checkUserExists(formValues.email);
    if (userExists) {
      setFormError('An account with the given email already exists.');
      return true;
    }
    return false;
  };

  const handleSignup = async (event) => {
    event.preventDefault();

    const userExists = await handleNextClick();
    if (userExists) {
      return;
    }
    // Reset errors
    setPasswordMatchError(false);
    setPasswordError(null);
    setFormError(null);

    // Check if passwords match
    if (formValues.password !== formValues.confirmPassword) {
      setFormError('Passwords do not match');
      return;
    }

    // Check for password criteria
    const isPasswordValid = Object.values(passwordRequirements).every(Boolean);
    if (!isPasswordValid) {
      setFormError('Password does not meet criteria');
      return;
    }

    // Proceed with the signup process

    const { firstName, lastName, email, phone, password, city, state, zip, address } = formValues;
    let attributes = {
      'custom:first_name': firstName,
      'custom:last_name': lastName,
      'email': email,
      'phone_number': phone,
      'custom:city': city,
      'custom:state': state,
      'custom:zip': zip,
      'custom:address': address,
    };

    onSignupComplete({ email, password, attributes });
  };

  // Optionally, you might want to automatically sign in the user after signup
  // await Auth.signIn(email, password);



const signInLink = `/login${returnUrl !== '/login' ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`;

return (
  <div className="flex items-start sm:items-center justify-center  min-h-screen bg-gray-200 py-5 overflow-y-auto">
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md w-full max-w-xs sm:max-w-md mb-8 sm:mb-2">
      <h2 className="flex justify-center text-2xl mb-4">Injsur Sign Up</h2>

      <form onSubmit={handleSignup}>
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formValues.firstName}
          onChange={handleInputChange}
          className="border p-2 mb-2 w-full rounded"
          required
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formValues.lastName}
          onChange={handleInputChange}
          className="border p-2 mb-2 w-full rounded"
          required
        />
        <div className="flex justify-between space-x-2">
          <input
            type="text"
            name="city"
            placeholder="City"
            value={formValues.city}
            onChange={handleInputChange}
            className="border p-2 mb-2 w-1/3 rounded"
            required
          />
          <select
            name="state"
            value={formValues.state}
            onChange={handleInputChange}
            className="border p-2 mb-2 w-1/3 rounded text-gray-800"
            required
          >
            <option value="" disabled>Select State</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>

          <input
            type="text"
            name="zip"
            placeholder="Zip Code"
            value={formValues.zip}
            onChange={handleInputChange}
            className="border p-2 mb-2 w-1/3 rounded"
            required
          />
        </div>
        <input
          type="text"
          name="address"
          placeholder="Street Address"
          value={formValues.address}
          onChange={handleInputChange}
          className="border p-2 mb-2 w-full rounded"
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formValues.email}
          onChange={handleInputChange}
          className="border p-2 mb-2 w-full rounded"
          required
        />
        <PhoneInput
          name="phone"
          placeholder="Phone Number"
          value={formValues.phone}
          onChange={(value) => handleInputChange({ target: { name: 'phone', value } })}
          className="border p-2 mb-2 w-full rounded"
          defaultCountry="US"
          required
        />

        <input
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Password"
          value={formValues.password}
          onChange={handlePasswordChange}
          className="border p-2 mb-2 w-full rounded"
          required
        />
        <div className="mb-4">
          <label className="inline-flex items-center text-gray-700 text-sm">
            <input
              type="checkbox"
              className="mr-2 leading-tight h-4 w-4"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
            />
            <span className="text-md">Show Password</span>
          </label>
        </div>


        {/* Password criteria messages */}
        <p className={passwordRequirements.minLength && passwordRequirements.uppercase && passwordRequirements.lowercase && passwordRequirements.number && passwordRequirements.specialChar ? 'text-green-600' : 'text-red-600'}>
          • At least 8 characters
        </p>
        <p className={passwordRequirements.uppercase ? 'text-green-600' : 'text-red-600'}>
          • At least one uppercase letter
        </p>
        <p className={passwordRequirements.lowercase ? 'text-green-600' : 'text-red-600'}>
          • At least one lowercase letter
        </p>
        <p className={passwordRequirements.number ? 'text-green-600' : 'text-red-600'}>
          • At least one number
        </p>
        <p className={passwordRequirements.specialChar ? 'text-green-600' : 'text-red-600'}>
          • At least one special character
        </p>
        <input
          type={showPassword ? "text" : "password"}
          name="confirmPassword"
          placeholder="Confirm Password"
          value={formValues.confirmPassword}
          onChange={handleInputChange}
          className="border p-2 my-2 w-full rounded"
          required
        />

        {/* Error message display */}
        {formError && (
          <p className="text-red-600">{formError}</p>
        )}

        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded w-full transition-colors duration-300 hover:bg-blue-600"
        >
          Next
        </button>
      </form>

      <p className="mt-4 text-gray-600 text-center">
        Already have an account? <a href={signInLink} className="text-blue-500">Sign in</a>
      </p>
    </div>
  </div>
);
};

export default Signup;
