import React from 'react';
import { Link } from 'react-router-dom';

import Header2 from './Header2';
import Footer from './Footer';
const AboutUs = () => {
    return (
        <div>
            <Header2 />

            <div className="min-h-screen bg-gray-100 py-16 px-4 sm:px-10 md:px-20 lg:px-40 transition duration-200 animate-fade-up">
                <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-green-900 mb-10 text-center" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                    About Injsur.AI
                </h1>

                <section className="mb-10">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green-900 mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Overview
                    </h2>
                    <p className="text-lg sm:text-xl md:text-2xl text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        As avid players of Fantasy Football over the last 30 years, we have witnessed the massive growth and evolution of the game driven by media availability, websites, apps, and new types of leagues with daily fantasy taking us to levels never before seen.
                    </p>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green-900 mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        The Issue
                    </h2>
                    <p className="text-lg sm:text-xl md:text-2xl text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Every year, every week, every game, players get injured. It’s the nature of the game. When players are drafted early, with high daily prices or when bet as player props (over/under), these injuries generally cost wins and create losses. Typically the majority of players and gamblers tend to wrap teams, bets, around the same key players. When any one is injured, it triggers total losses. The same applies to most gamblers with respect to multiple parlays each week. When our subscribers can avoid these players or get an advantage on the probability of injury or subpar performances due to prior injury, they gain a crucial edge on victory.
                    </p>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green-900 mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Problem
                    </h2>
                    <p className="text-lg sm:text-xl md:text-2xl text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Fantasy Football and now “Player Prop” betting are inherently skill-based; however, luck will always play a certain role. More often than not, that luck can be injury-related. Likely, anyone reading this has had their team, bet, daily entry, or best ball team lose due to an injury. Virtually all other information and predictive sites and apps are focused around scoring projections. While this is nice information to have, it won’t determine wins or losses for you. If your picks or players are injured or underperform as a result of injury, their ranking will not matter, and you will not win.
                    </p>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green-900 mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Our Solution
                    </h2>
                    <p className="text-lg sm:text-xl md:text-2xl text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        With cutting-edge Artificial Intelligence Machine Learning technology and our patent-pending Frailty Score Engine, Injsur.ai provides deep insight to assist with drafting and betting.
                    </p>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green-900 mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Differentiator
                    </h2>
                    <p className="text-lg sm:text-xl md:text-2xl text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Injsur.ai’s AI ML architecture takes thousands of data points, parameters, and history to compile players’ realistic probability of injury and performances affected. We identify value, best drafting position, and player prices for daily, betting, and more.
                    </p>
                </section>

                <section className="mb-10">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-green-900 mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Our Mission
                    </h2>
                    <p className="text-lg sm:text-xl md:text-2xl text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        To reduce the “unknowns” that can destroy wins, leagues, and profitable bets by helping players more consistently win by hedging bets using the world’s latest technology, providing vastly superior insight and information over all current analytics in the market.
                    </p>
                    <p className="text-lg sm:text-xl md:text-2xl text-gray-700 mt-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        We will continue to advance our system, add new features, all to ensure that our subscribers can gain a competitive advantage and have the most accurate and up-to-date information on what we believe is the most important part of fantasy and betting success: Injuries.
                    </p>
                </section>

                <section className="mb-10">
                    <p className="text-lg sm:text-xl md:text-2xl font-bold text-gray-700" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Subscribe and start winning today!!
                    </p>

                </section>
                <div className="flex justify-center items-center">
                    <Link to="/sign-up">
                        <button
                            className="flex font-bold mt-4 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 mt-4 rounded-full justify-center transition duration-200"
                            style={{
                                fontFamily: 'Montserrat, sans-serif',
                                backgroundColor: '#d8bf82',
                                color: 'black',
                                borderColor: 'white',
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                        >
                            CREATE FREE ACCOUNT
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
