import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import PlayerCardsSection from './PlayerCardsSection';

import Header from './Header';
import Footer from './Footer';
import img1 from '../images/greenBackground.jpeg'; // Adjust the path based on your project structure
import img2 from '../images/injsur2.jpeg'; // Adjust the path based on your project structure
import img3 from '../images/injsur3.jpg';
import img4 from '../images/injsur4.png';
import img5 from '../images/injsur5.jpg';
import img6 from '../images/injsur6.jpg';
import img7 from '../images/injsur7.jpg';
import img8 from '../images/injsur8.jpg';
import img9 from '../images/injsur9.jpg';
import img10 from '../images/injsur10.jpg';
import imgfootball from '../images/injsurFootball.jpg';
import imgmgm from '../images/mgm2.png';
import imgbet365 from '../images/bet3652.png';
import imgfanatics from '../images/fanatics2.png';
import imgcaesars from '../images/caesars2.png';
import CookieBanner from './CookieBanner';



const Home = () => {
    return (
        <div className="relative min-h-screen bg-transparent">
            <Header />


            {/* Main Content */}
            <div className="relative z-10">
                {/* Section 1 */}
                <section className="min-h-screen relative flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${img1})`, backgroundPosition: 'center 20%' }}>
                    <div className="text-center text-white px-4 flex flex-col items-center justify-start h-full mb-10">
                        <h1
                            className="mt-6 sm:mt-10 md:mt-10 lg:mt-10 xl:text-[5vw] lg:text-[6vw] md:text-[7vw] sm:text-[8vw] text-[10vw] font-bold lg:mx-70 animate-fade-up"
                            style={{ fontFamily: 'Montserrat, sans-serif' }}
                        >
                            INJURY FRAILTY SCORES
                        </h1>

                        {/* Line below the h1 */}
                        <span
                            style={{
                                display: 'block',
                                margin: '10px auto',
                                height: '0.5px',
                                width: '65%',
                                backgroundColor: '#d8bf82',
                            }}
                            className="animate-fade-up"
                        />

                        <p
                            className="text-xl sm:text-2xl md:text-4xl mb-4 max-w-6xl mx-auto animate-fade-up"
                            style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}
                        >
                            PREDICTIVE ARTIFICIAL INTELLIGENCE <br />
                            CHANGING THE GAME OF <br className="block md:hidden" />
                            FANTASY SPORTS AND GAMBLING

                        </p>


                        {/* Line below the p */}
                        <span
                            style={{
                                display: 'block',
                                margin: '7px auto',
                                height: '0.5px',
                                width: '65%',
                                backgroundColor: '#d8bf82',
                            }}
                            className="animate-fade-up"
                        />
                        <p className="text-lg font-bold sm:text-xl md:text-2xl text-center mx-8 mb-2 mt-4 animate-fade-up" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            How Frailty Scores Work
                        </p>
                        <p className="text-lg sm:text-xl md:text-2xl text-center mx-8 animate-fade-up" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            Frailty Scores are produced by the Injsur patent-pending AI engine which is built on a comprehensive dataset including player demographics, performance statistics, current physical performance metrics, detailed injury history, workload data, and more.
                        </p>
                        <p className="text-lg sm:text-xl md:text-2xl text-center mx-8 mt-2 animate-fade-up" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            The probabilities are on both a weekly and season-long basis.

                            This allows users to understand both short-term risks for upcoming games and long-term risks over the course of the season.
                        </p>
                        <Link to="/sign-up">
                            <button
                                className="font-bold mt-8 sm:mt-8 md:mt-8 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 rounded-full transition duration-200 animate-fade-up"
                                style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    backgroundColor: '#d8bf82',
                                    color: 'black',
                                    borderColor: 'white',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                            >
                                CREATE FREE ACCOUNT
                            </button>
                        </Link>
                    </div>
                </section>

                {/* Section 2 */}
                <section className="h-auto py-12 sm:py-12 md:py-12 relative flex flex-col bg-cover text-white" style={{ fontFamily: 'Montserrat, sans-serif', backgroundImage: `url(${img9})`, backgroundPosition: 'center 20%' }}>
                    <p className="text-4xl sm:text-5xl md:text-7xl font-bold mb-10 sm:mb-16 md:mb-10 text-left ml-8">
                        PLAYER CARDS
                    </p>

                    <p className="text-lg sm:text-xl md:text-2xl text-left mx-8" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                        Injsur.AI Player Cards provide easy access to crucial insights and probabilities of health and performance for NFL players, helping you make the best decisions possible empowered by real data science. Flip the cards for a sample of Injsur.AI player statistics.
                        Injury data for players is refreshed every hour, while player statistics are updated daily.

                    </p>

                    {/* Centered color-coded rectangles for injury risk levels */}
                    {/* Centered color-coded rectangles for injury risk levels with padding */}
                    <div className="flex justify-center mt-8 space-x-8 px-4 sm:px-8">
                        <div className="flex items-center">
                            <div className="w-6 h-6 bg-red-500 mr-2"></div>
                            <p className="text-lg sm:text-xl md:text-2xl" style={{ fontFamily: 'Montserrat, sans-serif' }}>High Risk</p>
                        </div>
                        <div className="flex items-center">
                            <div className="w-6 h-6 bg-yellow-400 mr-2"></div>
                            <p className="text-lg sm:text-xl md:text-2xl" style={{ fontFamily: 'Montserrat, sans-serif' }}>Average Risk</p>
                        </div>
                        <div className="flex items-center">
                            <div className="w-6 h-6 bg-green-500 mr-2"></div>
                            <p className="text-lg sm:text-xl md:text-2xl" style={{ fontFamily: 'Montserrat, sans-serif' }}>Low Risk</p>
                        </div>
                    </div>

                    <p className="text-[0.895rem] font-bold text-white mt-8 mx-8" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        * The cards displayed here are for demonstration purposes only and do not reflect live data as the real application does. Please <a href="/sign-up" className="underline">subscribe</a> to receive live frailty scores and data.
                    </p>

                    <PlayerCardsSection />



                </section>




                {/* Section 3 */}
                <section className="h-auto relative flex items-center justify-center text-green-900">
                    <div className="flex w-full">
                        {/* Left side: Football image taking 33% of the section, hidden on mobile */}
                        <div className="hidden lg:block w-2/5">
                            <img src={imgfootball} alt="Football" className="h-full w-full object-cover" />
                        </div>
                        {/* Right side: Text overlaid on img8 taking 67% of the section */}
                        <div className="w-full lg:w-3/5 relative flex flex-col justify-center items-center text-left"
                            style={{
                                backgroundImage: `url(${img8})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center right', // Shift img8 to the right
                            }}>
                            <div className="px-10 py-12 sm:px-20 md:px-40 lg:px-52" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                <p className="text-2xl sm:text-3xl md:text-4xl lg:text-3xl mb-6 uppercase">
                                    <span className='font-bold'>INJSUR.AI</span> STANDS AT THE FOREFRONT OF SPORTS TECHNOLOGY, SPECIALIZING IN PREDICTIVE HEALTH ANALYTICS TRAINED SPECIFICALLY FOR NFL PLAYERS THROUGH ITS ADVANCED AI PLATFORM.
                                </p>
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                    With our proprietary and patent pending Machine Learning models, <span className='font-bold'>Injsur.AI</span> provides continuous data updates and invaluable insights into injury probabilities which affect game results and your winnings.
                                </p>

                                <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl mb-4 font-bold" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                    {/* $14.99 Season Pass
                                    <br></br>
                                    Subscribe today and receive a 20% discount on 2025 NFL season                               */}
                                    Join Today!
                                      </p>
                                {/* <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl mb-4 font-bold" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                    In the near future, we will offer innovative customizable insurance solutions against player injuries for hedging fantasy sports and other betting scenarios.
                                </p> */}
                                <Link to="/sign-up">
                                    <button
                                        className="font-bold mt-4 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 rounded-full transition duration-200"
                                        style={{
                                            fontFamily: 'Montserrat, sans-serif',
                                            backgroundColor: '#d8bf82',
                                            color: 'black',
                                            borderColor: 'white',
                                        }}
                                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                                    >
                                        CREATE FREE ACCOUNT
                                    </button>

                                </Link>
                            </div>
                        </div>
                    </div>
                </section>





                {/* Section 3 */}
                <section className="h-auto py-16 sm:py-20 md:py-24 relative flex flex-col items-left justify-left text-left bg-cover px-10 sm:px-10 md:px-20 lg:px-60 text-white" style={{ backgroundImage: `url(${img9})`, backgroundPosition: 'center 20%' }}>
                    <div className="w-full lg:w-1/2">
                        <p className="text-4xl sm:text-5xl md:text-6xl font-bold mb-10 sm:mb-16 md:mb-20 uppercase" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            INJSUR.AI DELIVERS COMPREHENSIVE DATA SETS:
                        </p>
                        <ul className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-6 list-disc list-outside space-y-4 ml-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            <li>Player performance metrics: data such as career touches, age, games played, and more</li>
                            <li>Injury history: detailed records of past injuries, including type and severity</li>
                            <li>Game conditions: information on weather, field type, and other environmental factors</li>
                        </ul>
                        <Link to="/learn-more">
                            <button
                                className="font-bold mt-4 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 ml-6 rounded-full transition duration-200"
                                style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    backgroundColor: '#d8bf82',
                                    color: 'black',
                                    borderColor: 'white',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                            >
                                LEARN MORE
                            </button>

                        </Link>
                    </div>
                </section>








                {/* Combined Section 4 */}
                <section
                    className="h-full py-16 sm:py-20 md:py-24 relative flex flex-col items-left justify-left bg-no-repeat px-10 sm:px-10 md:px-20 lg:px-40 text-green-950"
                    style={{
                        backgroundImage: `url(${img10})`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center'
                    }}
                >
                    {/* Testimonials */}
                    <p className="text-4xl sm:text-5xl md:text-5xl font-bold mb-10 sm:mb-10 md:mb-10 text-left" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        TESTIMONIALS
                    </p>
                    <ul className="flex flex-col sm:flex-row justify-center items-start text-lg sm:text-xl md:text-2xl lg:text-3xl mb-6 text-left space-y-8 sm:space-y-0 sm:space-x-24 list-none">
                        <li className="flex items-center space-x-10 sm:space-x-10">
                            <div className="text-left">
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug" style={{ fontFamily: 'Montserrat, sans-serif' }}>As an avid FF player and bettor for over two decades, Injsur is absolutely top notch information. Highly recommend!</p>
                                <p className="text-[1.5rem] mt-4 font-bold">- JAY B.</p>
                            </div>
                        </li>
                        <li className="flex items-center space-x-4 sm:space-x-6">
                            <div className="text-left">
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug" style={{ fontFamily: 'Montserrat, sans-serif' }}>Injsur.AI is going to make all the difference in how I approach the rest of the season.</p>
                                <p className="text-[1.5rem] mt-4 font-bold">- DR. D ALLEN</p>
                            </div>
                        </li>
                        <li className="flex items-center space-x-4 sm:space-x-6">
                            <div className="text-left">
                                <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug" style={{ fontFamily: 'Montserrat, sans-serif' }}>Injsur’s detailed analytics on usage rate, body of work and frailty have already made a positive impact for me!</p>
                                <p className="text-[1.5rem] mt-4 font-bold">- RUSS R.</p>
                            </div>
                        </li>
                    </ul>
                    <div className="flex justify-center">
                        <Link to="/about">
                            <button
                                className="font-bold mt-12 sm:mt-12 md:mt-12 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 rounded-full transition duration-200 animate-fade-up"
                                style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    backgroundColor: '#d8bf82',
                                    color: 'black',
                                    borderColor: 'white',
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                            >
                                ABOUT US
                            </button>
                        </Link>
                    </div>


                    {/* Horizontal line */}
                    {/* <hr className="w-full border-t-2 border-[#d8bf82] my-12 sm:my-16 md:my-20" /> */}

                    {/* Expert Analysis */}
                    {/* <p className="text-4xl sm:text-5xl md:text-5xl font-bold mb-10 sm:mb-16 md:mb-20 text-left" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        EXPERT ANALYSIS
                    </p>
                    <ul className="flex flex-col sm:flex-row justify-center items-start text-lg sm:text-xl md:text-2xl lg:text-4xl mb-6 text-left space-y-8 sm:space-y-0 sm:space-x-8 list-none">
                        <li className="flex flex-col items-start">
                            <p className="text-[1.5rem] mb-2 font-bold">July 24th, 2024</p>
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>Top 15 Fantasy TEs for 2024: Scoring Big and Staying on the Field</p>
                            <a href="/articles" className="text-[1.5rem] mt-auto text-green-950 underline font-bold hover:underline">
                                READ MORE
                            </a>
                        </li>
                        <li className="flex flex-col items-start">
                            <p className="text-[1.5rem] mb-2 font-bold">July 24th, 2024</p>
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>From Rookie to Champ: 5 Reasons Fantasy Football is Your New Obsession</p>
                            <a href="/articles" className="text-[1.5rem] mt-auto text-green-950 font-bold underline hover:underline">
                                READ MORE
                            </a>
                        </li>
                        <li className="flex flex-col items-start">
                            <p className="text-[1.5rem] mb-2 font-bold">July 24th, 2024</p>
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>Top 10 Rookie Sleepers for 2024: Finding the Healthy Gems in the Rough</p>
                            <a href="/articles" className="text-[1.5rem] mt-auto text-green-950 font-bold underline hover:underline">
                                READ MORE
                            </a>
                        </li>
                        <li className="flex flex-col items-start">
                            <p className="text-[1.5rem] mb-2 font-bold">July 24th, 2024</p>
                            <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl leading-snug mb-4" style={{ fontFamily: 'Montserrat, sans-serif' }}>The New Era of Fantasy Football: Balancing Potential and Risk</p>
                            <a href="/articles" className="text-[1.5rem] mt-auto text-green-950 font-bold underline hover:underline">
                                READ MORE
                            </a>
                        </li>
                    </ul> */}
                </section>

                <section className="h-auto py-16 sm:py-16 md:py-16 flex items-left justify-left text-left bg-cover px-10 text-green-950" style={{ backgroundImage: `url(${img4})`, backgroundPosition: 'center 20%' }}>
                    <div className="">
                        <p
                            className="text-4xl sm:text-5xl md:text-6xl font-bold mb-10 sm:mb-16 md:mb-20 uppercase whitespace-nowrap"
                            style={{ fontFamily: 'Montserrat, sans-serif' }}
                        >
                            COMING SOON
                        </p>

                        <p className="text-lg sm:text-xl md:text-3xl text-left" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            In the near future, Injsur.AI will introduce innovative new features to improve your user experience including variable data sliders, scoring projections, expert analysis, one-to-three week injury predictions and much more, including a customizable team builder that allows you to tailor your experience with your preferred players to deliver crucial insights you can’t find anywhere else.
                        </p>
                        <br />
                        <p className="text-lg sm:text-xl md:text-3xl text-left" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                            Ultimately, Injsur.AI will provide player injury insurance solutions to help you hedge against losses for fantasy sports and other betting scenarios.
                        </p>
                        <div className="flex justify-left">
                            <Link to="/sign-up">
                                <button
                                    className="font-bold mt-12 sm:mt-12 md:mt-12 text-black text-lg sm:text-xl md:text-2xl px-6 py-4 mb-4 rounded-full transition duration-200 animate-fade-up"
                                    style={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        backgroundColor: '#d8bf82',
                                        color: 'black',
                                        borderColor: 'white',
                                    }}
                                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#a78c5d')}
                                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#d8bf82')}
                                >
                                    CREATE FREE ACCOUNT
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>




                {/* Section 8 */}
                <section className="h-auto py-24 sm:py-20 md:py-24 relative flex flex-col items-left justify-left bg-green-950 px-10 sm:px-10 md:px-10 lg:px-10 xl:px-10 text-black">
                    <p className="text-4xl sm:text-3xl  md:text-5xl font-bold mb-10 sm:mb-16 md:mb-10 text-left" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        CONTACT US
                    </p>
                    <div className="flex flex-wrap justify-left items-left  gap-8 md:gap-20 lg:gap-20  w-full text-white text-3xl">
                        <ul className="list-none">
                            <li className="mb-4"><a href="mailto:support@injsur.ai" className="underline">support@injsur.ai</a></li>
                            <li className="mb-4"><a href="mailto:partners@injsur.ai" className="underline">partners@injsur.ai</a></li>
                            <li className="mb-4"><a href="mailto:advertisers@injsur.ai" className="underline">advertisers@injsur.ai</a></li>
                            <li className="mb-4"><a href="mailto:advertisers@injsur.ai" className="underline">feedback@injsur.ai</a></li>
                        </ul>

                    </div>

                </section>


                {/* Section 8
                <section className="h-auto py-24 sm:py-20 md:py-24 relative flex flex-col items-left justify-left bg-green-950 px-4 sm:px-10 md:px-10 lg:px-10 xl:px-10 text-black">
                    <p className="text-4xl sm:text-3xl md:text-3xl font-bold mb-10 sm:mb-16 md:mb-20 text-left" style={{ fontFamily: 'Montserrat, sans-serif', color: '#d8bf82' }}>
                        INJSUR.AI PARTNERSHIPS
                    </p>
                    <div className="flex flex-wrap justify-center items-center gap-8 md:gap-20 lg:gap-20 w-full">
                        <img src={imgmgm} alt="Partner MGM" className="h-auto max-w-[200px] sm:max-w-[150px] md:max-w-[180px] lg:max-w-[220px] object-contain" />
                        <img src={imgbet365} alt="Partner Bet365" className="h-auto max-w-[200px] sm:max-w-[150px] md:max-w-[180px] lg:max-w-[220px] object-contain" />
                        <img src={imgfanatics} alt="Partner Fanatics" className="h-auto max-w-[200px] sm:max-w-[150px] md:max-w-[180px] lg:max-w-[220px] object-contain" />
                        <img src={imgcaesars} alt="Partner Caesars" className="h-auto max-w-[200px] sm:max-w-[150px] md:max-w-[180px] lg:max-w-[220px] object-contain" />
                    </div>
                </section> */}



            </div>
            <CookieBanner />

            <Footer />
        </div>
    );
};

export default Home;
